
.info-container {
    position: relative;
}

.overlay {
    position: absolute;
    right: 50%;
    height: 80vh;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    transition: right 1.5s ease;  /* Smooth transition effect */
    z-index: 1000;  /* Ensure it's on top of other elements */
    font-size: medium;
    border-top-left-radius: 15px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 55px;
    /* box-shadow: 0px 1px 5px rgba(76, 76, 76, 0.6); */
    /* make back ground change every 10 seconds */
    background-image: linear-gradient(-105deg,
        rgb(0, 12, 186), 
        rgb(12, 18, 95), 
        rgb(6, 10, 72),
        rgb(2, 5, 50));
    background-size: 200% 200%; /* Creates a large background to animate */
    animation: gradientShift 15s ease infinite; /* 10 seconds animation */

    .MySaas-title {
        text-align: center;
        font-size: larger;
    }
    #my-saas-apps-container {
        overflow-y: scroll;
        height: 90%;
    }
    #my-saas-apps-container::-webkit-scrollbar {
        background-color: rgba(0, 0, 0, 0.10);
        width: 7px;
    }
    #my-saas-apps-container::-webkit-scrollbar-thumb {
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
    }
    #MySaas-app {
        height: 35vh;
        padding: 10px;
        text-align: center;
        img {
            transition: 1s;
        }
    }
    #MySaas-app:hover {
        img {
            filter: brightness(120%);
            border-radius: 23px;
            transition: 1s;
        }
    }
    #saas-logo {
        max-width: 50%;
    }
    #app-title {
        font-size: medium;
        color: white;
        text-decoration: none;
        transition: 0.6s;
    }
    #app-title:hover {
        color: rgb(188, 188, 255);
        transition: 0.6s;
    }
    #app-description {
        margin: auto;
        color: rgba(255, 255, 255, 0.75);
        font-size: small;
        width: 80%;
    }

}

/* When the overlay is visible, slide it into view */
.slide-in {
    right: 5%;  /* Slide into view from the right */
}

/* When the overlay is hidden, slide it out of view */
.slide-out {
    right: -100%;  /* Slide it out of view */
}

@media (max-width: 600px) {
    .overlay {
        width: 50%;
        top:8%;
    }
}
@media (min-width: 600px) {
    .overlay {
        width: 45%;
        top:15%;
    }
}

@keyframes gradientShift {
    0% {
        background-position: 0% 0%;
    }
    70% {
        background-position: 20% 50%;
    }
    80% {
        background-position: 100% 75%;
    }
    100% {
        background-position: 0% 0%;
    }
}