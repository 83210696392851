/* BASE STYLING */
body {
    overflow: hidden;
    height: 100vh;
    background-image: linear-gradient(white, white, rgb(236, 236, 236), rgb(206, 206, 206));
    #root {
        height: 100%;
    }
}

#page-nav {
    background-color: rgb(255, 255, 255);
    /* background-color: rgb(1, 0, 70); */
    color: white;
    margin-bottom: 5px;
    box-shadow: 0px 3px 5px 0px rgba(206, 206, 206, 0.382);
    #nav-center {
        h3#nav-title {
            text-align: center;
            font-weight: 100;
        }
        a {
            text-decoration: none;
            color: rgb(172, 169, 199);
            transition: 0.6s;
            animation: color-change 3s infinite;
        }
        a:hover {
            color: rgb(0, 16, 93);
            transition: 0.6s;
        }
    }
    #nav-right {
        text-align: right;
        font-weight: 100;
        a {
            text-decoration: none;
            color: rgb(221, 221, 221);
            padding-right: 10px;
        }
        a:hover {
            color: rgb(255, 255, 255);
        }
    }
}


#page-content {
    margin: auto;
    #content-win-1-l {
        display: flex;
        justify-content: center;
        align-items: center;
        .link-window {
            height: 100%;
            width: 85%;
            /*  animation */
            .link-container {
                display: flex;
                align-items: center;
                height: 19%;
                position: relative;
                animation: link-ease-in 2s;
                animation-fill-mode: forwards;
                animation-timing-function: ease;
                a {
                    text-decoration: none;
                    background: linear-gradient(135deg, 
                        rgba(195, 191, 227, 0.559),
                        rgba(186, 183, 209, 0.615),
                        rgba(172, 169, 199, 0.622),
                        rgba(78, 64, 208, 0.842),
                        rgb(17, 7, 105),
                        rgb(10, 2, 85));
                    background-size: 300% 200%;
                    background-position: left;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: background-position 2.5s ease;
                    transition: 1s;
                }
                a:hover {
                    background-position: right;
                    transform: translateX(20px);
                    transition: 1s;
                }
            }
        }
    }
    
    #content-win-1-r {
        display: flex;
        justify-content: center;
        align-items: center;
        .intro-window {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 90%;
            width: 85%;
            transition: 0.5s;
        }
        .info-container {
            max-height: 100%;
            transition: 0.5s;
            text-align: center;
            background-image: linear-gradient(130deg, 
                rgb(247, 247, 247, 0.105),
                rgb(247, 247, 247, 0.105), 
                rgba(255, 255, 255, 0.876));
            background-size: 250% 350%;
            animation: gradientShiftMe 10s ease infinite; /* 10 seconds animation */
            box-shadow: 0px 3px 5px 0px rgba(206, 206, 206, 0.159);
            border-radius: 20px;
            .head-shot {
                max-width: 70%;
                border-radius: 240px;
                position: relative;
                transition: 0.5s;
                margin-top: 20px;
                
                border: 1px solid rgb(210, 210, 210, 0.1);
            }
            .about-me {
                margin:0;
                padding: 10px;
                position: relative;
                animation: img-ease-in 2.5s infinite;
                animation-fill-mode: forwards;
                animation-timing-function: linear;
                transition: 0.6s;
                color:  rgb(149, 145, 180);
                /* background-color:rgba(209, 209, 214, 0.226); */
                border-radius: 15px;
                /* border: solid 1px rgba(172, 169, 199, 0.538);; */
                strong {
                    font-weight: 300;
                }
            }
            #tech-logo {
                max-height: 100%;
                border-radius: 25px;
                background-color: rgba(238, 238, 238, 0.858);
                opacity: 0.9;
                margin: 10px;
                margin-bottom: 20px;
            }
            #tech-logo:hover {
                box-shadow: 0px 3px 6px 0px rgba(255, 255, 255, 0.9);
            }
        }
        .info-container:hover {
            background-color: rgba(255, 255, 255, 0.164);
            box-shadow: 0px 3px 10px 0px rgba(148, 148, 148, 0.384);
            /* box-shadow: 0px 3px 5px 0px rgb(255, 255, 255); */
            transition: 0.6s;
            img {
                transition: 0.6s;
                background-color: rgba(255, 255, 255, 0.602);
            }
            .head-shot {
                border: 1px solid rgb(210, 210, 210, 0.25);
            }
            p {
                color: rgb(0, 21, 126);
                transition: 0.6s;
            }
        }
    }
}


/* key frames */
@keyframes link-ease-in {
    from {left: -500px;}
    to {left: 0px;}
}



/* MOBILE STYLING */
@media (max-width: 600px) {
    #page-nav {
        height: 5vh;
        h3 {
            font-size: 17px;
        }
    }
    #content-win-1-l {
        height: 30%;
        font-size: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    #content-win-1-r {
        height: 70%;
        font-size: xx-small;
        .info-container {
            max-width: 95%;
        }
        #tech-logo {
            width: 9%;
        }
    }
}


/* DESKTOP STYLING */
@media (min-width: 600px) {
    #page-nav {
        height: 10vh;
    }
    #page-content {
        height: 90vh;
    }
    #content-win-1-l {
        height: 90vh;
        font-size: 7.5vw;
    }
    #content-win-1-r {
        height: 90vh;
        font-size: small;
        .info-container {
            max-width: 80%;
        }
        #tech-logo {
            width: 9%;
        }
    }
}

@keyframes gradientShiftMe {
    0% {
      background-position: 0% 50%;
    }
    70% {
        background-position: 20% 50%;
    }
    80% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
}